import '@app/core/elements/Footer.scss';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { I18n, SetLanguage } from '@app/core';
import { Update } from '@app/store/reducers/data.reducer';

interface Props { className?: string, styles?: any }
export const Footer: React.FC<Props> = ({ className, styles }) =>
{

    const dispatch = useDispatch();
    const year = new Date().getFullYear();

    const [IsDark, setIsDark] = useState(false);

    useEffect(() =>
    {
        let mode = document.querySelector("html").classList.contains("dark") == true;
        setIsDark(mode);
    }, []);

    const HandleDark = () =>
    {
        document.querySelector("html")?.classList.toggle("dark");
        let mode = document.querySelector("html").classList.contains("dark") == true;
        setIsDark(mode);
    };

    const HandleLang = (e) =>
    {
        SetLanguage(e.target.value);
        dispatch(Update({ landing: I18n.t("defaults.landing") }));
    }

    return (
        <footer className="p-4 bg-gray-50 sm:p-6 dark:bg-gray-800">
            <div className="mx-auto max-w-screen-xl">
                <div className="sm:flex sm:items-center sm:justify-between">
                    <div className="flex text-sm text-gray-500 sm:text-center dark:text-gray-400"> © {year} <a href="https://bytereact.com" className="mx-2 hover:underline">{I18n.t("elem.footer.company")}</a> {I18n.t("elem.footer.rights")} <span className="mx-2">{I18n.t("elem.footer.powered")} <a href="https://asari.cloud" target="_blank" className="hover:underline">asari</a></span></div>
                    <div className="flex mt-4 space-x-6 sm:justify-center sm:mt-0">
                        <a href="#" className="text-gray-500 hover:text-gray-900 dark:hover:text-white"><i className='fa-brands fa-github'></i></a>
                        <a href="#" className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:fill-gray-500"><i className='fa-brands fa-tiktok'></i></a>
                        <a href="#" className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:fill-gray-500"><i className='fa-brands fa-twitch'></i></a>
                        <a href="#" className="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:fill-gray-500"><i className='fa-brands fa-discord'></i></a>
                    </div>                
                </div>
            </div>
        </footer>
    );
};