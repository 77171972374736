import { combineReducers } from 'redux';

import * as fromApp from '@app/store/reducers/app.reducer';
import * as fromData from '@app/store/reducers/data.reducer';

export interface StoreState
{
    app: fromApp.State,
    data: fromData.State,
}

export const reducers = combineReducers<StoreState>({
    app: fromApp.reducer,
    data: fromData.reducer,
});

export default reducers;