import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { reducers } from "@app/store/store.state";
import { storeSaga } from "@app/store/store.saga";

const sagaMiddleware = createSagaMiddleware();
export const store = createStore(reducers, compose(applyMiddleware(...[sagaMiddleware]),  /*window["__REDUX_DEVTOOLS_EXTENSION__"] && window["__REDUX_DEVTOOLS_EXTENSION__"]() */));
sagaMiddleware.run(storeSaga);

export default store;