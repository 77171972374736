import React, { useEffect, useState } from "react";
export * from '@app/core/locale';

//- async -//
export function useAsync(fn, args, callback)
{
    useEffect(() =>
    {
        let mounted = true;
        fn(args).then(data => { (mounted) ? callback(data) : null; });
        return () => { mounted = false; };
    }, [args])
}
export function useDebounce(delay = 350)
{
    const [search, setSearch] = useState(null);
    const [searchQuery, setSearchQuery] = useState(null);

    useEffect(() =>
    {
        const delayFn = setTimeout(() => setSearch(searchQuery?.target?.value?.trim()), delay);
        return () => clearTimeout(delayFn);
    }, [searchQuery, delay]);

    return [search, setSearchQuery];
}
export const Wait = (ms) =>
{
    return new Promise((resolve, reject) =>
    {
        setTimeout(() => { resolve(true) }, ms);
    });
}
export const Until = (fn, ms) =>
{
    ms = !ms ? 15000 : ms;
    return new Promise((resolve, reject) =>
    {
        let interval = setInterval(() =>
        {
            if (!fn) return;
            resolve(true);
            clearInterval(interval);
        }, ms);
    });
}
export const ParseHTML = (raw: string) =>
{
    var trgx = new RegExp(/(<\/?\w+((\s+\w+(\s*=\s*(?:".*?"|'.*?'|[^'">\s]+))?)+\s*|\s*)\/?>)/gm);
    var matches = raw.match(trgx);
    return { __html: raw };
}

//- generators -//
export const CreateAction = (type: any) =>
{
    return (data: any) => ({ type: type, payload: data });
}
export const CreateActions = (list: any) =>
{
    let actions = {};
    for (var i in list)
        actions[i] = CreateAction(list[i]);
    return actions;
}

//- helpers -//
export const scrollTo = (id: string) =>
{
    document.querySelector(`#${id}`)?.scrollIntoView({ behavior: "smooth", inline: "nearest" });
};
export interface StyleSheet { [key: string]: React.CSSProperties; }

export const parseIndex = (ns, p) =>
{
    return ns.split(".").slice(0, ns.split(".").length - p).join(".");
}
export const getAtIndex = (obj, ns, p = 0) =>
{
    let nsx = parseIndex(ns, p);
    return nsx.split(".").reduce((o, i) => o[i], obj);
}
export const setAtIndex = (obj, ns, value, rm?: boolean) =>
{
    if (typeof ns == 'string')
        return setAtIndex(obj, ns.split('.'), value, rm);
    else if (ns.length == 1 && value !== undefined && rm === true)
        return Array.isArray(obj) ? obj.splice(ns[0], 1) : delete obj[ns[0]];
    else if (ns.length == 1 && value !== undefined)
        return obj[ns[0]] = value;
    else if (ns.length == 0)
        return obj;
    else
        return setAtIndex(obj[ns[0]], ns.slice(1), value, rm);
}

export const GetOverlay = (e, selector) =>
{
    let el: any = document.querySelector(selector);
    let ct = e.currentTarget.offsetTop - el.offsetTop - el.scrollTop - 16;
    return { ct: ct, top: el.offsetTop, left: el.offsetLeft, width: el.offsetWidth, height: el.offsetHeight };
}