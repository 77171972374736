import '@app/core/elements/Field.scss';
import React, { useState, useEffect } from 'react';

interface Props
{
    type?: React.HTMLInputTypeAttribute,
    value: any,

    className?: string,
    placeholder?: string,
    children?: any,

    readonly?: boolean,
    disabled?: boolean

    onBlur?: any,
    onValid?: any,
    onChange?: any,
}
export const Field: React.FC<Props> = ({ className = '', type = 'text', value, placeholder, disabled = false, readonly = false, onBlur, onValid, onChange, children }) =>
{
    const [error, setError] = useState(null);
    const [change, setChange] = useState(0);

    const _disabled = disabled ? 'disabled' : '';

    const HandleChange = (e: any) =>
    {
        let check = typeof onValid == 'function' ? onValid(e.target.value) : true;
        setError(check);

        setChange(change + 1);
        onChange(e.target.value);
    }

    return (
        <>
            <input className={`field ${_disabled} ${className} ${!!error ? 'border !border-red-400' : ''}`} type={type} value={!value ? '' : value}
                placeholder={placeholder}
                onChange={HandleChange}
                onBlur={onBlur}
                disabled={disabled}
                readOnly={readonly}
            />
            {!!error && <span className='relative float-right h-0 text-red-500 -top-9 right-3'><i className="fa-sharp fa-solid fa-circle-exclamation" /></span>}
        </>
    )
};