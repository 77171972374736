import '@app/screens/Home/Views/Main.scss';

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, Outlet, useLocation } from 'react-router-dom';

import { I18n } from '@app/core';
import { Footer } from '@app/core/elements';

interface Props { }
export const MainView: React.FC<Props> = () =>
{
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [path, setPath] = useState("");
    const isMain = location.pathname === "/";

    return (
        <>
            <Outlet />
        </>
    );
};
