import '@app/screens/Home/Views/Landing.scss';

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { SetLanguage, I18n } from '@app/core';
import { Asari } from '@app/services';
import { Fetch, Update } from '@app/store/reducers/data.reducer';
import { selectLanding, selectPage } from '@app/store/selectors/data.selectors';

import { Button, Footer } from '@app/core/elements';
import { ContactView } from '@app/screens/Home/Elements/Contact';

interface Props { }
export const LandingView: React.FC<Props> = () =>
{
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { language } = useParams();
    let content = useSelector(selectLanding);

    const [cover, setCover] = useState(0);
    const hours = (new Date().valueOf() - new Date("2005.01.01").valueOf()) / 1000 / 60 / 60;
    const stats = { project: 14, customer: 432 }

    useEffect(() =>
    {
        SetLanguage((!!language) ? language : 'en');
        dispatch(Update({ landing: I18n.t("defaults.landing") }));
    }, []);

    const HandleScroll = async (e) =>
    {
        e.preventDefault();
        e.stopPropagation();
        e.currentTarget.blur();

        let el = document.querySelector(e.currentTarget.pathname.replace("/", "#"));
        await el.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        return;
    }

    return (
        <main className="min-w-full min-h-screen font-sans bg-white">

            <Helmet>
                <title>{content?.seo?.title}</title>
                <meta name='description' content={content?.seo?.description} />
                <meta property="og:type" content={content?.seo?.type} />
                <meta property="og:image" content={content?.seo?.image} />
                <meta property="og:title" content={content?.seo?.title} />
                <meta property="og:description" content={content?.seo?.description} />
            </Helmet>

            <header className="max-w-screen-xl mx-auto text-gray-600 body-font">
                <div className="flex flex-row justify-between px-5 py-4">
                    <a href="https://bytereact.com" className="flex items-center">
                        <div className="hidden w-40 h-14 md:block"><div className="branding-long"></div></div>
                        <div className="w-12 h-12 md:hidden"><div className="branding"></div></div>
                    </a>
                    <nav className="flex flex-wrap items-center justify-center">
                        <a href="about" className="mr-5 cursor-pointer hover:text-gray-900" onClick={HandleScroll}>{I18n.t("elem.header.about")}</a>
                        <a href="contact" className="mr-5 cursor-pointer hover:text-gray-900" onClick={HandleScroll}>{I18n.t("elem.header.contact")}</a>
                    </nav>
                </div>
            </header>

            <section className="max-w-screen-xl min-h-screen mx-auto text-gray-600">
                <div className="flex flex-col items-center px-5 py-24 md:flex-row">
                    <div className="w-5/6 mb-10 lg:max-w-lg lg:w-full md:w-1/2 md:mb-0">
                        <img className="object-contain object-center rounded" alt="hero" src={content?.hero?.imageUrl} />
                    </div>
                    <div className="flex flex-col items-center text-center lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 md:items-start md:text-left">
                        <h1 className="mb-4 text-4xl font-medium text-gray-900 title-font sm:text-4xl">{content?.hero?.title}</h1>
                        <p className="mb-8 leading-relaxed">{content?.hero?.short}</p>
                        <div className="flex justify-center">
                            <Button className="tertiary" onClick={async () =>
                            {
                                let el = document.querySelector("#contact");
                                await el.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
                            }}>{content?.hero?.buttonA}</Button>
                        </div>
                    </div>
                </div>
            </section>

            <section id="about" className="max-w-screen-xl min-h-screen mx-auto text-gray-600">
                <div className="container flex flex-wrap px-5 py-24 mx-auto">
                    <div className="flex flex-wrap content-start mt-auto mb-auto -mx-4 lg:w-1/2 sm:w-2/3 sm:pr-10">
                        <h1 className="mb-4 text-2xl font-medium text-gray-900 title-font sm:text-4xl">For advertisers</h1>
                        <div className="w-full px-4 mb-6 sm:p-4">
                            <h1 className="mb-4 text-2xl font-medium text-gray-900 title-font sm:text-2xl">Discover and Engage</h1>
                            <div className="leading-relaxed">Browse through a diverse pool of content creators across platforms like TikTok, Instagram, YouTube, Videogames and more. Find the perfect match for your brand.</div>
                        </div>
                        <div className="w-full px-4 mb-6 sm:p-4">
                            <h1 className="mb-4 text-2xl font-medium text-gray-900 title-font sm:text-2xl">Order Promotional Content</h1>
                            <div className="leading-relaxed">Need a product placement or a shout-out video? Simply place an order with your chosen creator. Specify your requirements, and let the magic happen.</div>
                        </div>
                    </div>
                    <div className="w-full mt-6 overflow-hidden rounded-lg lg:w-1/2 sm:w-1/4 sm:mt-0">
                        <img className="object-contain object-center rounded" src={"/assets/promo/bg_elevate.svg"} alt="advertisers" />
                    </div>
                </div>
            </section>

            <section id="about" className="max-w-screen-xl min-h-screen mx-auto text-gray-600">
                <div className="container flex flex-wrap px-5 py-24 mx-auto">
                    <div className="flex flex-wrap content-start mt-auto mb-auto -mx-4 lg:w-1/2 sm:w-2/3 sm:pr-10">
                        <h1 className="mb-4 text-2xl font-medium text-gray-900 title-font sm:text-4xl">For creators</h1>
                        <div className="w-full px-4 mb-6 sm:p-4">
                            <h1 className="mb-4 text-2xl font-medium text-gray-900 title-font sm:text-2xl">Getting paid made easy</h1>
                            <div className="leading-relaxed">No more waiting for weeks to receive your hard-earned money. With Stripe integration, creators receive their payments promptly. and automatically for every job!</div>
                        </div>
                        <div className="w-full px-4 mb-6 sm:p-4">
                            <h1 className="mb-4 text-2xl font-medium text-gray-900 title-font sm:text-2xl">Focus on creating</h1>
                            <div className="leading-relaxed">Say goodbye to job hunting, endless emails and proposals! Keep doing what you love! Creating content and let bytereact handle the rest.</div>
                        </div>
                    </div>
                    <div className="w-full mt-6 overflow-hidden rounded-lg lg:w-1/2 sm:w-1/4 sm:mt-0">
                        <img className="object-contain object-center rounded" src={"/assets/promo/bg_team.svg"} alt="creators" />
                    </div>
                </div>
            </section>

            <ContactView />
            <Footer />
        </main>
    );
};

/*

For Advertisers
Discover and Engage
Search and Connect: Browse through a diverse pool of content creators across platforms like TikTok, Instagram, YouTube, Games and more. Find the perfect match for your brand.
Order Promotional Content: Need a product placement or a shout-out video? Simply place an order with your chosen creator. Specify your requirements, and let the magic happen.
Secure Payments
Bytereact Secure: Our payment system ensures that transactions are safe and hassle-free. Pay for your campaigns securely through ByteReact.

For Content Creators
Instant Payouts
Get Paid Instantly: No more waiting for weeks to receive your hard-earned money. With Stripe integration, creators receive their payments promptly.
Focus on Creating: Say goodbye to job hunting! Keep doing what you love—creating content—and let ByteReact handle the rest.
Performance Insights
Track Your Success: Monitor your campaign performance, view your earnings, and manage your jobs—all within the ByteReact application.
Join the ByteReact community today and unlock endless possibilities for collaboration, creativity, and growth.

*/