
import React, { useState, useEffect } from 'react';

import { I18n } from '@app/core';
import { Asari } from '@app/services';
import { Button, Field } from '@app/core/elements';

interface Props { }
export const ContactView: React.FC<Props> = () =>
{
    const [count, setCount] = useState(0);
    const [contact, setContact]: any = useState({ done: false, name: "", email: "", message: "" });

    const isEnabled = contact?.name?.length >= 4 && contact?.email?.indexOf("@") !== -1 && contact?.message?.length > 50;

    useEffect(() =>
    {
        if (!contact.message)
            return;

        setCount(contact.message.length);
        return () => { setCount(0) }
    }, [contact])

    const HandleContact = async () =>
    {
        if (!!contact?.done)
            return;

        await Asari.database.Collection("contact").Doc(null).Set({
            owner: "TAMARIN",
            name: escapeHtml(contact.name),
            email: escapeHtml(contact.email),
            message: escapeHtml(contact.message)
        });
        setContact({ ...contact, done: true });
    };

    const escapeHtml = (unsafe) =>
    {
        return unsafe.replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");
    }

    return (
        <section id="contact" className="max-w-screen-xl min-h-screen mx-auto text-gray-600">
            <div className="container px-5 py-24 mx-auto">
                <div className="flex flex-col w-full mb-12 text-center">
                    <h1 className="mb-4 text-3xl font-medium text-gray-900 sm:text-3xl title-font">{I18n.t("elem.contact.title")}</h1>
                    <p className="mx-auto text-base leading-relaxed lg:w-2/3">{I18n.t("elem.contact.short")}</p>
                </div>
                {contact.done && <h1 className="mb-4 font-medium text-center text-gray-900 sm:text-3xl text-1xl title-font">{I18n.t("elem.contact.thanks", { name: contact.name })} <br /> {I18n.t("elem.contact.intouch")} </h1>}
                {!contact.done &&
                    <div className="mx-auto lg:w-1/2 md:w-2/3">
                        <div className="flex flex-wrap -m-2">
                            <div className="relative w-1/2 p-2">
                                <Field className="flex w-full primary bg-gray-50" onValid={(v) => (v.length <= 4)} onChange={(v) => { setContact({ ...contact, name: v }) }} value={contact?.name} type="text" placeholder={I18n.t("elem.contact.name")} />
                            </div>
                            <div className="relative w-1/2 p-2">
                                <Field className="flex w-full primary bg-gray-50" onValid={(v) => (v.indexOf("@") === -1)} onChange={(v) => { setContact({ ...contact, email: v }) }} value={contact?.email} type="email" placeholder={I18n.t("elem.contact.email")} />
                            </div>
                            <div className="relative w-full p-2">
                                <textarea onChange={(e) => { setContact({ ...contact, message: e.currentTarget.value }) }} value={contact?.message} id="message" name="message" placeholder={I18n.t("elem.contact.project")} className="w-full h-32 text-base resize-none field primary bg-gray-50 py-1px-3"></textarea>
                                <span className='relative float-right h-0 text-xs right-2 -top-5'>{count}/50</span>
                            </div>
                            <div className="flex w-full p-2">
                                <Button disabled={!isEnabled} onClick={HandleContact} className="flex self-center mx-auto primary">{I18n.t("elem.contact.button")}</Button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </section>
    );
};