import { Route } from "react-router-dom";

import { MainView, LandingView } from '@app/screens/Home';

import { EulaView } from '@app/screens/Home/Views/Eula';
import { PrivacyView } from '@app/screens/Home/Views/Privacy';

export const HomeRouter = (
    <>
        <Route path="/" element={<MainView />}>
            <Route index element={<LandingView />} />

            <Route path="/eula/:docId" element={<EulaView />} />
            <Route path="/pricacy/:docId" element={<PrivacyView />} />
            <Route path="/privacy/:docId" element={<PrivacyView />} />
            <Route path="/landing/:language" element={<LandingView />} />
        </Route>
    </>
);