import '@app/core/elements/Button.scss';
import React, { useState, useEffect } from 'react';

interface Props
{
    onClick?: any,
    className?: string,

    tip?: string,
    styles?: any,
    children?: any,
    disabled?: boolean
}
export const Button: React.FC<Props> = ({ className = 'button', onClick, tip = null, disabled = false, children }) =>
{
    const _disabled = disabled ? 'disabled' : '';

    const [spam, setSpam] = useState(0);
    const HandleClick = () =>
    {
        if (disabled) return;

        let now = new Date().valueOf();
        let diff = (now - spam) / 1000;
        if (diff < 1) return;

        onClick?.call();
        setSpam(now);
    }

    return (<div className={`button align-middle cursor-pointer rounded-md transition-all duration-200 ${className} ${_disabled}`} onClick={HandleClick}>{children}</div>);
    //return (<div className={`button text-base px-5 py-3 align-middle cursor-pointer rounded-md transition-all duration-200 ${className}${_disabled}`} onClick={HandleClick}>{children}</div>);
};