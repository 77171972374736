import { store } from '@app/store';
import { takeLatest, takeEvery, put, all, select, call, cancel } from 'redux-saga/effects';
import { ActionTypes, Update } from "@app/store/reducers/data.reducer";

import { Asari } from '@app/services';

function* FetchSaga(action): any
{
    try 
    {
        const { docId, selector, override } = action.payload;

        let result: any = null;
        switch (selector)
        {
            case 'page':
                result = yield call(async () => (await Asari.database.Collection("pages").Doc(docId).Fetch()));
                result = result;
                break;
            case 'landing':
                result = yield call(async () => (await Asari.functions.Call("FetchUsers", action.payload)));
                result = result;
                break;
            default: break;
        }

        let res: any = {};
        if (!override)
            res[selector] = (!!result) ? result : [];
        if (!!override && override != "NONE")
            res[override] = (!!result) ? result : [];
        if (!!override && override == "NONE")
            res = (!!result) ? result : [];

        yield put(Update({ ...res }));
    }
    catch (error)
    {
    }
}

export function* watchDataSaga()
{
    yield takeEvery(ActionTypes.Fetch, FetchSaga);
}
export default watchDataSaga;