import { createSelector } from "reselect";

import { StoreState } from "@app/store/store.state";
import { State } from "@app/store/reducers/data.reducer";

const DataState = (state: StoreState): State => state.data;
export const selectError = createSelector([DataState], (state) => state.error);
export const selectStatus = createSelector([DataState], (state) => state.status);

export const selectPage = createSelector([DataState], (state) => state.page);
export const selectLanding = createSelector([DataState], (state) => state.landing);