import { CreateAction, I18n } from "@app/core";

export enum ActionTypes
{
    Push = '[DATA]ServerUpdate',
    Fetch = '[DATA]Fetch',
    Search = '[DATA]Search',
    Update = '[DATA]Update'
}

export const Push = CreateAction(ActionTypes.Push);
export const Fetch = CreateAction(ActionTypes.Fetch);
export const Update = CreateAction(ActionTypes.Update);

export interface State
{
    error: any,
    status: any,

    page: any;
    landing: any;
}
export const initial: State =
{
    error: null,
    status: null,

    page: I18n.t("defaults.landing"),
    landing: null
}

export function reducer(state = initial, action): State
{
    switch (action.type)
    {
        case ActionTypes.Update:
            state = { ...state, ...action.payload };
            return state;
        default:
            return state;
    }
}
