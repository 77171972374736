import { all } from "redux-saga/effects";

import { watchAppSaga } from "@app/store/sagas/app.saga";
import { watchDataSaga } from "@app/store/sagas/data.saga";


export function* storeSaga()
{
    try
    {
        yield all([
            watchAppSaga(),
            watchDataSaga(),
        ])
    }
    catch (error)
    {

    }
}

export default storeSaga;