import { CreateAction } from "@app/core";

export enum ActionTypes
{
    Load = '[APP]Load',
    Update = '[APP]Update',
}

export const Load = CreateAction(ActionTypes.Load);
export const Update = CreateAction(ActionTypes.Update);

export interface State
{
    ready: boolean,
    url: string,
    state: string,
    config: any,
    settings: any,
    language: any,
    navigation: any
}

export const initial: State =
{
    ready: false,
    url: "",
    state: "",
    config: null,
    language: null,
    settings: null,
    navigation: null
}

export function reducer(state = initial, action): State
{
    switch (action.type)
    {
        case ActionTypes.Load:
            return state = action.payload;
        case ActionTypes.Update:
            state = { ...state, ...action.payload };
            return state;
        default:
            return state;
    }
}