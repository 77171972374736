import "@app/index.scss";

import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import { store } from '@app/store';
import { Provider } from 'react-redux';
import { AppRouter } from '@app/index.router';

//https://bit.cloud
//https://tailwind-elements.com
//https://blog.bitsrc.io/how-we-build-our-design-system-15713a1f1833

const root = createRoot(document.getElementById('root'));
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
    root.render(<HelmetProvider><Provider store={store}><Router><AppRouter /></Router></Provider></HelmetProvider>);
else
    root.render(<React.StrictMode><HelmetProvider><Provider store={store}><Router><AppRouter /></Router></Provider></HelmetProvider></React.StrictMode>);

    //hydrateRoot(document.getElementById('root'), <React.StrictMode><HelmetProvider><Provider store={store}><Router><AppRouter /></Router></Provider></HelmetProvider></React.StrictMode>);
