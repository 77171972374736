import React from 'react';
import { Routes } from "react-router-dom";

import { HomeRouter } from '@app/screens/Home';

interface Props { }
export const AppRouter: React.FC<Props> = () =>
{
  return (
    <Routes>
      {HomeRouter}
    </Routes>
  )
}